import "./App.css";
import Header from "./components/Header";
import Body from "./components/Body";
import Footer from "./components/Footer";
import PreHeader from "./components/PreHeader";
import { useEffect } from "react";
import { projects } from "./db";

function App() {
	useEffect(() => {
		const hamburger_menu = document.getElementById("hamburger_menu");
		const header = document.getElementById("header");
		hamburger_menu.addEventListener("click", () => {
			header.classList.toggle("active");
		});
	}, []);

	return (
		<>
			<PreHeader />
			<Header />
			<Body projects={projects} />
			<div className="legacy">
				<h1>I am here...</h1>
				<p>Finally</p>
				<h4>Arriving</h4>
			</div>
			<Footer />
		</>
	);
}

export default App;
