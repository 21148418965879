import React, { useEffect } from "react";
import "../styles/Body.css";

const Body = ({ projects }) => {
	useEffect(() => {
		// eslint-disable-next-line no-undef
		new Swiper(".project-slider", {
			speed: 1000,
			loop: true,
			autoplay: {
				delay: 10000,
				disableOnInteraction: false,
			},
			slidesPerView: 1,
			pagination: {
				el: ".project-swiper-pagination",
				type: "bullets",
				clickable: true,
			},
			breakpoints: {
				320: {
					slidesPerView: 1,
					spaceBetween: 20,
				},
				768: {
					slidesPerView: 1,
					spaceBetween: 20,
				},
				1200: {
					slidesPerView: 1,
					spaceBetween: 20,
				},
			},
		});
	}, []);
	return (
		<>
			<div className="body-container">
				<section className="text" id="text">
					<h1 className="opening=title" id="opening-title">
						This is my <span>Story</span>
					</h1>
					<div className="little-box">
						<p>Believing</p>
					</div>
					<div className="box">
						<div className="big-box">
							<h2>
								Creativity <span>is Power</span>
							</h2>
						</div>
					</div>
				</section>
			</div>
			<section className="main-section" id="portfolio">
				<div className="box">
					<div className="big-box">
						<h1>Projects</h1>
						<div>
							<div id="projects">
								<div className="container">
									<div
										className="project-slider swiper"
										data-aos="fade-up"
										data-aos-delay="100"
									>
										<div className="swiper-wrapper">
											{projects.map((project) => (
												<div className="swiper-slide" key={project.id}>
													<div className="projects-block">
														<div className="proj-image">
															<img
																src={project.image}
																className="image"
																alt="screenshot"
															/>
														</div>
														<div className="proj-contents">
															<span className="proj-name">{project.name}</span>
															<small>
																Technologies
																<span>{project.tech}</span>
															</small>
															<p className="desc">
																<span>
																	{"---"}Description{"---"}
																</span>
																<br /> {project.desc}
															</p>
															<a href={project.link}>Project- Link</a>
															<button className="source">
																<a href={project.source}>Source Code</a>
															</button>
														</div>
													</div>
												</div>
											))}
										</div>
										<div className="project-swiper-pagination"></div>
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>
			</section>
		</>
	);
};

export default Body;
