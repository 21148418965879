import React from "react";
import "../styles/Footer.css";
import { FaGithub } from "react-icons/fa";

const Footer = () => {
	return (
		<footer className="footer" id="footer">
			<div className="signature">
				ℑ 𝔴𝔦𝔩𝔩 𝔠𝔬𝔫𝔱𝔦𝔫𝔲𝔢 𝔱𝔬 𝔟𝔲𝔦𝔩𝔡, 𝔟𝔢𝔠𝔞𝔲𝔰𝔢 𝔦𝔱 𝔞𝔩𝔩 𝔰𝔱𝔞𝔯𝔱𝔢𝔡 𝔣𝔯𝔬𝔪 𝔞𝔫 𝔦𝔡𝔢𝔞. 𝔄𝔰 𝔡𝔯𝔢𝔞𝔪𝔰
				𝔦𝔫 𝔯𝔢𝔞𝔩𝔦𝔱𝔶 𝔞𝔯𝔢 𝔢𝔫𝔠𝔩𝔬𝔰𝔦𝔫𝔤 𝔦𝔫 𝔱𝔬 𝔱𝔥𝔢𝔦𝔯 𝔣𝔲𝔩𝔩𝔢𝔰𝔱 𝔭𝔬𝔱𝔢𝔫𝔱𝔦𝔞𝔩. ℑ 𝔴𝔦𝔩𝔩 𝔠𝔯𝔢𝔞𝔱𝔢
				𝔥𝔦𝔰𝔱𝔬𝔯𝔶 𝔴𝔦𝔱𝔥 𝔢𝔳𝔢𝔯𝔶 𝔟𝔯𝔢𝔞𝔱𝔥 𝔱𝔥𝔢𝔯𝔢 𝔦𝔰
			</div>
			<div className="content">
				<div className="col">
					<li>
						<a href="#">Home</a>
					</li>
					<li>
						<a href="#portfolio">Projects</a>
					</li>
					<li>
						<a href="#header">About</a>
					</li>
					<li>
						<a href="https://www.ethernmyth.cf">Official Website</a>
					</li>
					<li>
						<a href="https://github.com/AetherSearch">
							<FaGithub />
						</a>
					</li>
					<li>
						<span>Site by Victor.</span>
					</li>
					<li>All rights &copy; reserved. 2022</li>
				</div>
			</div>
		</footer>
	);
};

export default Footer;
