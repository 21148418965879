import React, { useEffect } from "react";
import {
	FaGithub,
	FaInternetExplorer,
	FaLanguage,
	FaDatabase,
	FaDesktop,
	FaCloud,
	FaBinoculars,
	FaDoorClosed,
} from "react-icons/fa";
import "../styles/Header.css";
import "../styles/Stack.css";

const Header = () => {
	useEffect(() => {
		const preHeader = document.getElementById("preHead");
		const header = document.getElementById("header");
		const body = document.getElementById("root");
		const nav = document.querySelector(".nav");
		window.addEventListener("scroll", function () {
			preHeader.style.opacity = 1 - +this.window.pageYOffset / 500 + "";
			preHeader.style.top = +this.window.pageYOffset + "px";
			preHeader.style.backgroundPositionY = +this.window.pageYOffset + "px";
			header.style.opacity = +this.window.pageYOffset / 500 + "" - 0.25;
			body.style.background = "white";
			nav.style.position = "fixed";
		});
		// eslint-disable-next-line no-undef
		new Swiper(".services-slider", {
			speed: 600,
			loop: true,
			autoplay: {
				delay: 5000,
				disableOnInteraction: false,
			},
			slidesPerView: "auto",
			pagination: {
				el: ".swiper-pagination",
				type: "bullets",
				clickable: true,
			},
			breakpoints: {
				320: {
					slidesPerView: 1,
					spaceBetween: 20,
				},
				768: {
					slidesPerView: 2,
					spaceBetween: 20,
				},
				1200: {
					slidesPerView: 4,
					spaceBetween: 20,
				},
			},
		});
	}, []);
	return (
		<header className="header" id="header">
			<nav className="nav">
				<div className="logo">
					<a href="#">AETHER SEARCH</a>
				</div>
				<div className="links">
					<ul>
						<li>
							<a href="#">Home</a>
						</li>
						<li>
							<a href="#header">About</a>
						</li>
						<li>
							<a href="#portfolio">Project</a>
						</li>
						<li>
							<a href="https://github.com/AetherSearch">
								<FaGithub />
							</a>
						</li>
						<li>
							<a href="https://www.ethernmyth.cf">
								<FaInternetExplorer />
							</a>
						</li>
					</ul>
				</div>
				<div className="hamburger-menu" id="hamburger_menu">
					<div className="bar"></div>
				</div>
			</nav>

			<div>
				<h1>Begin to explore</h1>
				<h2>My technology stack</h2>
			</div>
			<div className="stack" id="stack">
				<div id="services">
					<div className="container">
						<div
							className="services-slider swiper"
							data-aos="fade-up"
							data-aos-delay="100"
						>
							<div className="swiper-wrapper">
								<div className="swiper-slide">
									<div className="services-block">
										<i>
											<FaDesktop />
										</i>
										<span>OS</span>
										<p className="separator">
											Windows, Linux (Kali Linux, Ubuntu, Debian, Arch Linux ),
											MacOS, Android{" "}
										</p>
									</div>
								</div>
								<div className="swiper-slide">
									<div className="services-block">
										<i>
											<FaLanguage />
										</i>
										<span>LANGUAGES</span>
										<p className="separator">
											C#, ASP.NET, Java, Python, PHP, JavaScript, TypeScript,
											Delphi{" "}
										</p>
									</div>
								</div>

								<div className="swiper-slide">
									<div className="services-block">
										<i>
											<FaDatabase />
										</i>
										<span>DATABASES</span>
										<p className="separator">
											MSSQL, MySQL, PostgreSQL, MongoDB, MS Access{" "}
										</p>
									</div>
								</div>

								<div className="swiper-slide">
									<div className="services-block">
										<i>
											<FaCloud />
										</i>
										<span>CLOUD</span>
										<p className="separator">
											Cloudflare, Azure, Google Cloud Platform, Github,
											Firebase, GitLab{" "}
										</p>
									</div>
								</div>

								<div className="swiper-slide">
									<div className="services-block">
										<i>
											<FaBinoculars />
										</i>
										<span>FRONTEND</span>
										<p className="separator">
											ReactJS, NextJS, Svelte, HTML5, CSS3, AngularJS, Vite,
											Webpack{" "}
										</p>
									</div>
								</div>

								<div className="swiper-slide">
									<div className="services-block">
										<i>
											<FaDoorClosed />
										</i>
										<span>BACKEND</span>
										<p className="separator">
											.NET Core 3.0, Node JS | Express, Flask{" "}
										</p>
									</div>
								</div>
							</div>
							<div className="swiper-pagination"></div>
						</div>
					</div>
				</div>
			</div>
		</header>
	);
};

export default Header;
