import React, { useEffect, useRef } from "react";
import "../styles/Preheader.css";
import Typed from "typed.js";

const PreHeader = () => {
	const titles = [
		{
			id: 1,
			text: "I am a Software developer,",
		},
		{
			id: 2,
			text: "A frontend developer",
		},
		{
			id: 3,
			text: "And a backend developer.",
		},
		{
			id: 4,
			text: "Inception is Art 😃",
		},
		{
			id: 5,
			text: "I welcome you.",
		},
	];
	const el = useRef(null);
	useEffect(() => {
		const typed = document.querySelector(".typed");
		if (typed) {
			let list = titles.map((title) => title.text);
			new Typed(el.current, {
				strings: list,
				loop: true,
				typeSpeed: 100,
				backSpeed: 50,
				backDelay: 2000,
				showCursor: false,
			});
		}
	}, []);
	return (
		<pre>
			<section className="awe-section" id="preHead">
				<div className="page-title">
					<h1>
						Hi, I'm...<span>Victor</span>
						<small>Creator of Ethern Myth and Aether Search</small>
					</h1>
					<h2 className="page-title__title">
						<span className="typed" ref={el}></span>
					</h2>
					<div className="scrolldown-container">
						<span></span>
						<span></span>
						<span></span>
					</div>
				</div>
			</section>
		</pre>
	);
};

export default PreHeader;
