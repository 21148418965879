export const projects = [
	{
		id: 1,
		name: "Pattern App",
		tech: "C#10, ASP.NET",
		desc: "C# console project to solve mathematical pattern such as cubic patterns",
		image: "./images/Home.png",
		link: "",
		source: "https://github.com/AetherSearch/AetherPatterns",
	},
        {
		id: 2,
		name: "Forum App",
		tech: "Python, Flask, HTML&CSS, Bootstrap",
		desc: "Q and A app for any user to communicate on",
		image: "./images/Untitled.png",
		link: "https://replit.com/@AetherSearch?tab=community",
		source: "https://github.com/AetherSearch/forum-app",
	},
];
